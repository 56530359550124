





















































































import Vue from 'vue';
import ClickOutside from 'vue-click-outside';
import { Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import { validate, ValidationData } from '@/utils/helpers/validation';
import { BASE_BLACK_COLOR } from '@/utils/constants';

interface selectedValue {
  value: String | Number;
  name: String;
}
Component.registerHooks(['validations']);

@Component({
  directives: {
    ClickOutside,
  },
  computed: {
    ...mapGetters({
      editReport: 'wizard/editReport',
      editReportSelectedFilters: 'wizard/editReportSelectedFilters',
    }),
  },
})
export default class DropDown extends Vue {
  @Prop() options!: Array<Object>;
  @Prop() placeholder: String | undefined;
  @Prop() disabled: Boolean | undefined;
  @Prop() value!: Object;
  @Prop({ default: '' }) label!: String;
  @Prop() id!: String;
  @Prop() stepId!: String;
  @Prop() validation: Array<ValidationData> | undefined;

  [x: string]: any;
  activeButton: Boolean = false;
  error: Boolean = false;
  message: String = '';
  baseBlackColor = BASE_BLACK_COLOR;
  symbol = {
    product_kaufpreis: '€',
    product_darlehen: '€',
    product_beleihung: '%',
    product_tilgung: '%',
  };

  validate() {
    this.$v.value.$touch();
    this.error = this.$v.$error;
    // @ts-ignore
    this.message = this.validation[0].message;
    return { error: this.$v.$error };
  }

  clearValidation() {
    this.error = false;
  }

  handleChangeDropDown() {
    this.activeButton = !this.activeButton;
    if (this.error) {
      this.error = false;
    }
  }
  selectItem(selectedItem: selectedValue) {
    this.$emit('input', selectedItem.value);
    this.$emit('item', { [`${this.id}`]: selectedItem.name });
  }
  hide() {
    this.activeButton = false;
  }
  validations() {
    return {
      value: {
        required,
        isSet: (val) => {
          const valid = this.validation ? [...this.validation] : [];
          return valid.map((validation: any) => {
            return validate(validation, val);
          });
        },
      },
    };
  }
  get currentItem() {
    if (Number.isNaN(+this.value)) {
      return this.options.find((item: any) => item.name === this.value) || {};
    }
    return this.options.find((item: any) => item.value === this.value) || {};
  }
}
