












































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapMutations } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import CheckBox from '@/components/fmh_components/common/CheckBox/CheckBox.vue';
import Dialog from '@/components/fmh_components/common/Dialog/Dialog.vue';

import { validate, ValidationData } from '@/utils/helpers/validation';
import { BASE_BLACK_COLOR, SELECTED_COLOR } from '@/utils/constants';

interface DataOptions {
  name: string;
}

interface Data {
  map(arg0: (item: any) => void);
  findIndex(arg0: (item: any) => boolean);
  find(arg0: (item: any) => boolean);
  options: Array<DataOptions>;
  length: number;
}

interface DataMeta {
  open_all: Boolean;
  select_all: Boolean;
}
Component.registerHooks(['validations']);

@Component({
  components: { CheckBox, Dialog },
  computed: {
    ...mapGetters({
      report_type: 'reports/report_type',
      maxColumns: 'wizard/maxColumns',
      updateExistingReport: 'reports/updateExistingReport',
    }),
  },
  methods: {
    ...mapMutations({ saveSumMeta: 'reports/saveSumMeta' }),
  },
})
export default class CheckboxGroup extends Vue {
  @Prop() options!: Data;
  @Prop() id!: String;
  @Prop() label!: String;
  @Prop({ default: () => {} }) value!: Object;
  @Prop() stepId!: String;
  @Prop() validation!: Array<ValidationData>;
  @Prop() meta: DataMeta | undefined;
  @Prop() alle?: Object;
  @Prop() selectedGroups;

  [x: string]: any;
  message: String = '';
  color: String = BASE_BLACK_COLOR;
  selectedColor = SELECTED_COLOR;
  arraySize = 16;
  values: Object = {};
  groups: Object = {};
  dialogGroup: Object = {};
  isShowModal: Boolean = false;
  withGroups: Boolean = false;
  selected: Array<String> = [];
  maxColumns: number | any;
  report_type: number | any;
  updateExistingReport: Object | any;
  allGroupsSelected = {};
  saveSumMeta!: (selectedCheckboxesMetaSum) => {};

  get isAllGroupsSelectionBtnActive() {
    const isAllSelected: boolean[] = [];
    Object.keys(this.groups).forEach((groupName) => {
      if (
        this.counterOffers[groupName].counterOffers > 0 &&
        this.counterOffers[groupName].counterOffers ===
          this.groups[groupName].length
      ) {
        isAllSelected.push(true);
      } else {
        isAllSelected.push(false);
      }
    });
    return !isAllSelected.includes(false);
  }
  get checkboxGroupStyle() {
    if (this.report_type === 2) {
      return this.options.length === 4
        ? { 'min-width': '160px' }
        : { 'min-width': '130px' };
    }
    if (this.report_type === 1) {
      return { 'min-width': '170px' };
    }
    return null;
  }
  get chunks() {
    const result = {};
    Object.keys(this.groups).map((key) => {
      const sliced_array: object[] = [];
      for (let i = 0; i < this.groups[key].length; i += this.arraySize) {
        sliced_array.push(this.groups[key].slice(i, i + this.arraySize));
      }
      return Object.assign(result, { [key]: sliced_array });
    });
    return result;
  }

  get groupValues() {
    const result = {};
    Object.entries(this.groups).forEach(([name, group]: any) => {
      if (name === 'Vermittler') {
        group.forEach(({ value, financer }) => {
          const exists = financer.every(({ value }) => this.values[value]);
          exists && Object.assign(result, { [value]: true });
        });
      }
    });
    return result;
  }

  get counterOffers() {
    return Object.entries(this.groups || {}).reduce(
      (data, [name, group]: any) =>
        Object.assign(data, {
          [name]: {
            counterOffers: group.filter(({ value, financer }) =>
              Array.isArray(financer)
                ? financer.some(({ value }) => this.values[value])
                : this.values[value]
            ).length,
            financer: group
              .map(
                ({ financer }) =>
                  []
                    .concat(financer || [])
                    .filter(({ value }) => this.values[value]).length
              )
              .reduce((a, b) => a + b, 0),
          },
        }),
      {}
    );
  }

  get groupIndeterminate() {
    const result = {};
    Object.entries(this.groups).forEach(([name, group]: any) => {
      if (name === 'Vermittler') {
        group.forEach(({ value, financer }) => {
          const exists = financer.some(({ value }) => this.values[value]);
          exists && Object.assign(result, { all: true, [value]: true });
        });
      }
    });
    return result;
  }

  get amountOffers() {
    let count = null;
    (this.groups as any).Vermittler?.map(
      (item) => (count += item.financer?.length || 0)
    );
    return count;
  }

  handleAllChecked() {
    const selected = {};
    const values = {};
    if (!Object.keys(this.allGroupsSelected).length) {
      Object.keys(this.groups).forEach((group) => {
        Object.assign(selected, { [group]: true });
      });
      this.allGroupsSelected = selected;
    } else {
      Object.keys(this.allGroupsSelected).forEach((group) => {
        Object.assign(selected, { [group]: !this.allGroupsSelected[group] });
      });
      this.allGroupsSelected = selected;
    }
    for (let i = 0; i < this.options.length; i += 1) {
      if (this.options[i].category === 'Vermittler') {
        this.options[i].financer.map((item) => {
          Object.assign(values, {
            [item.value]: this.allGroupsSelected[this.options[i].category],
          });
        });
      }
      if (this.options[i].category !== 'Vermittler') {
        this.$set(
          this.values,
          this.options[i].value,
          this.allGroupsSelected[this.options[i].category]
        );
      }
    }
    this.values = Object.assign({}, this.values, values);
    this.$emit('checked', { checked: this.result });
    if (this.alle && Object.keys(this.alle || {}).length) {
      const groups = {};
      Object.keys(this.alle).forEach((key) => {
        if (this.allGroupsSelected[key]) {
          Object.assign(groups, { [key]: this.alle?.[key] });
        }
      });
      this.$emit('groupsSelected', groups);
    }
  }
  generateGroupName(groupName) {
    if (groupName === 'Banken über Vermittler') {
      return 'Banken über Raisin';
    }
    return groupName;
  }
  handleGroupsSelected(groupName) {
    if (
      this.alle &&
      Object.keys(this.alle || {}).length &&
      isFinite(this.alle[groupName])
    ) {
      if (this.groupSelected[groupName]) {
        this.$emit('groupsSelected', {
          ...this.selectedGroups,
          [groupName]: this.alle[groupName],
        });
      } else {
        const copy = { ...this.selectedGroups };
        delete copy[groupName];
        this.$emit('groupsSelected', copy);
      }
    }
  }
  toggleDialogGroup(groupName) {
    this.dialogGroup = {
      ...this.dialogGroup,
      ...{
        [groupName]: this.dialogGroup[groupName]
          ? !this.dialogGroup[groupName]
          : true,
      },
    };
  }
  chunksValues(items, arraySize) {
    let subarray: object[] = [];
    if (items.length > 2) {
      for (let i = 0; i < Math.ceil(items.length / arraySize); i++) {
        subarray[i] = items.slice(i * arraySize, i * arraySize + arraySize);
      }
      return subarray;
    }
    return [items];
  }
  handleChangeModal(val) {
    this.isShowModal = val;
  }
  handleChangeDialog(item) {
    item.dialog = !item.dialog;
    this.$forceUpdate();
  }
  isDisabledBtn(item) {
    return !this.groupIndeterminate[item.value];
  }
  handleChangeDialogGroup(groupName) {
    this.dialogGroup[groupName] = false;
  }
  onValuesChangeVermittler(val, item) {
    const values = {};
    item.financer.forEach(({ value }) => (values[value] = val));
    this.values = Object.assign({}, this.values, values);
    if (this.groupSelected['Vermittler']) {
      this.isShowModal = true;
      this.message = `Sie haben ${this.amountOffers} Angebote ausgewählt`;
    }
    this.$emit('checked', { checked: this.result });
  }
  onValuesChange(val, key, groupName) {
    const values = Object.assign({}, this.values, { [key]: val });
    this.selected = Object.entries(values)
      .filter(([, value]) => !!value)
      .map(([key]) => key);
    this.values = values;
    this.$emit('checked', {
      checked: this.result,
      selectedSum: this.selectedSum,
      stepId: this.stepId,
    });
    this.handleGroupsSelected(groupName);
  }
  validate() {
    this.$v.values.$touch();
    return { error: this.$v.$error, message: this.validation[0].message };
  }
  isDisabled(item) {
    if (this.report_type === 3 && this.stepId !== 'banken_step') {
      if (this.selected.some((key) => item.value === key)) return false;
      return this.selectedSum >= this.maxColumns;
    }
    return false;
  }
  toggleAll(val) {
    for (let i = 0; i < this.options.length; i += 1) {
      this.$set(this.values, this.options[i].value, val);
    }
    this.$emit('checked', { checked: this.result });
  }
  toggleGroupSub(val, item) {
    const values = { [item.value]: val };
    item.financer.forEach(({ value }) =>
      Object.assign(values, { [value]: val })
    );
    this.values = Object.assign({}, this.values, values);
    this.$emit('checked', { checked: this.result });
  }
  toggleGroupVermittler(val, groupName) {
    const values = {};
    for (let i = 0; i < this.options.length; i += 1) {
      if (this.options[i].category === groupName) {
        this.options[i].financer.map((item) => {
          Object.assign(values, { [item.value]: val });
        });
      }
    }

    this.values = Object.assign({}, this.values, values);

    if (this.groupSelected['Vermittler']) {
      this.isShowModal = true;
      this.message = `Sie haben ${this.amountOffers} Angebote ausgewählt`;
    }
    this.$emit('checked', { checked: this.result });
  }
  toggleGroup(val, groupName) {
    for (let i = 0; i < this.options.length; i += 1) {
      if (this.options[i].category === groupName) {
        this.$set(this.values, this.options[i].value, val);
      }
    }
    this.handleGroupsSelected(groupName);
    this.$emit('checked', { checked: this.result });
  }
  groupBy(arr, property) {
    return arr?.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      if (memo.hasOwnProperty('Vermittler')) {
        memo['Vermittler'].map((item) => {
          return { ...item, ...{ dialog: false } };
        });
      }
      return memo;
    }, []);
  }
  validations() {
    return {
      values: {
        required,
        isSet: (value) => {
          const valid = [...this.validation, { type: 'allTruthy' }];
          const validations = valid.map((validation: any) => {
            return validate(validation, Object.values(value));
          });
          return (
            validations.filter((item) => item).length === validations.length
          );
        },
      },
    };
  }

  get isShowCheckAll() {
    if (this.meta !== undefined) {
      return this.meta.select_all;
    } else {
      return true;
    }
  }

  get selectedSum() {
    if (this.report_type === 3) {
      return this.selected
        .map((key) => {
          const option = this.options.find(({ value }) => value === key);
          return +option?.meta?.columns || 0;
        })
        .reduce((a, b) => a + b, 0);
    }
    return [];
  }

  get allSelected() {
    const arr = Object.values(this.values);
    return arr.filter((i) => i).length === this.options.length;
  }

  get groupSelected() {
    const result = {};
    Object.entries(this.groups).forEach(([name, group]: any) => {
      result[name] = group.every(({ value, financer }) =>
        Array.isArray(financer)
          ? financer.every(({ value }) => this.values[value])
          : this.values[value]
      );
    });
    return result;
  }

  get key() {
    const keys = Object.keys(this.values).sort((a, b) => {
      const indexA = this.options.findIndex((item) => item.value === a);
      const indexB = this.options.findIndex((item) => item.value === b);
      return indexA - indexB;
    });
    return keys.filter((k) => this.values[k]);
  }

  get result() {
    return { [`${this.id}`]: this.key };
  }

  @Watch('options', { immediate: true }) onChangeOptions() {
    this.withGroups = Object.values(this.options).some(
      (item) => !!item.category
    );
    const reportTypes = [1, 2];
    if (
      reportTypes.includes(+this.report_type) &&
      this.stepId === 'banken_step'
    ) {
      const order = {
        Direktbanken: 0,
        'Bundesweite Filialbanken': 1,
        'Regionale Filialbanken': 2,
        'Banken über Vermittler': 3,
        'Bausparkassen/Versicherungen': 4,
      };
      const groups = this.groupBy(this.options, 'category');
      this.groups = Object.keys(groups)
        .sort((a: string, b: string) => {
          if (order[a] === order[b]) {
            return 0;
          }
          return order[a] < order[b] ? -1 : 1;
        })
        .reduce((all, current) => {
          all[current] = groups[current];
          return all;
        }, {});
    } else {
      this.groups = this.groupBy(this.options, 'category');
    }
  }

  @Watch('value', { immediate: true }) onChangeValue() {
    if (this.value) {
      this.values = Object.assign({}, this.values, this.value);
    }
    this.selected = Object.entries(this.values)
      .filter(([, value]) => !!value)
      .map(([key]) => key);
    const sum = this.selected
      .map((key) => {
        const option = this.options.find(({ value }) => value === key);
        return +option?.meta?.columns || 0;
      })
      .reduce((a, b) => a + b, 0);
    if (
      this.report_type === 3 &&
      !!Object.keys(this.updateExistingReport).length
    ) {
      this.saveSumMeta(sum);
    }
  }
}
