export interface ValidationData {
  message: String;
  operand: Number;
  operator: String;
}

function parseOperator(operator, operand, value) {
  if (operator === 'ne') return value !== operand;
  if (operator === 'eq') return value === operand;
  if (operator === 'gte') return value >= operand;
  if (operator === 'lte') return value <= operand;
  if (operator === 'gt') return value > operand;
  if (operator === 'lt') return value < operand;
  if (operator === 'ngt') return Number(value) <= Number(operand);
}

export function validate({ type, operator, operand }, value) {
  switch (type) {
    case 'length': {
      return parseOperator(operator, operand, value.length);
    }
    case 'value': {
      return parseOperator(operator, operand, value);
    }
    case 'allTruthy': {
      return value.filter((item) => item)[0];
    }
    default:
      return false;
  }
}
