/* eslint-disable */
import { uid } from 'uid';
import { store } from '@/store';


export function modifyRowsForBaugeldTable(args) {
  const { refs, edit, values, editRow, currentStep} = args;
  const { anbieterAuswahl,
    column_filter,
    note,
    selection_title,
    selection_type,
    autoupdate_interval,
    PrevieTableBaugeld,
    baugeldTable,
    ...rest } = values;
  let componentsIds = ['product_kaufpreis_manual', 'product_darlehen_manual', 'product_zinsbindung', 'product_beleihung_manual', 'product_tilgung_manual'];
  const errors = [];

  if(values.product_volltilgung) {
    componentsIds = componentsIds.filter(item => item !== 'product_tilgung_manual');
  }

  componentsIds.forEach((key) => {
    const component = refs.stepContent?.[currentStep].$refs?.component[1]?.$refs?.selectionDialog?.find((item) => item.id === key);
    if (component) {
      const err = component.validate()?.error;
      if (err) errors.push(err);
    }
  })

  if (errors.length > 0) return;

  if (!edit) {
    const id = uid();
    store.commit('baugeld/setRowBaugeldTable', {
      ...rest,
      id,
    });
    store.commit('reports/setProductsFilters', {
      ...rest,
      id,
    });
    refs.stepContent?.[currentStep].resetSelectedValues();
    refs.stepContent?.[currentStep].$refs?.component[1].handleChangeDialog();
  } else {
    store.commit('baugeld/updateRow',
      Object.assign(rest, {
        id: editRow.id,
        idx: editRow.idx,
      })
    );
    store.commit(
      'reports/changeExistingProduct',
      Object.assign(rest, {
        id: editRow.id,
      })
    );
    refs.stepContent?.[currentStep].resetSelectedValues();
    refs.stepContent?.[currentStep].$refs?.component[1].handleChangeDialog();
  }
}
