



























































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { BASE_BLACK_COLOR } from '@/utils/constants';
import { mapGetters, mapMutations, MutationMethod } from 'vuex';

@Component({
  computed: {
    ...mapGetters({
      editRowBaugeldTable: 'baugeld/editRow',
      copyProduct: 'baugeld/copyProduct',
      isSelectionDialog: 'reports/getIsSelectionDialog',
    }),
  },
  methods: {
    ...mapMutations({
      modifySelectionDialog: 'reports/modifySelectionDialog',
    }),
  },
})
export default class SelectionDialog extends Vue {
  @Prop() options!: Array<object>;
  @Prop() values;

  isSelectionDialog!: Boolean;
  dialog: Boolean = false;
  color: String = BASE_BLACK_COLOR;
  editRowBaugeldTable!: Object;
  copyProduct!: Object;

  modifySelectionDialog!: MutationMethod;

  get model() {
    return this.isSelectionDialog;
  }

  set model(value) {
    this.modifySelectionDialog(value);
  }
  handleChangeDialog() {
    this.validateAll();
    this.modifySelectionDialog(false);
  }
  setValues(editOrCopy) {
    const { actions, icons, ...rest } = editOrCopy;
    Object.assign(this.values, rest, {});
  }

  get button() {
    return this.options.find((item) => {
      return (item as any).props.id === 'product_add';
    });
  }
  handleChange(id) {
    // HARDCODED:
    this.validateAll();
    this.$emit('item', { [id]: this.values[id] });
  }
  validateAll() {
    //@ts-ignore
    this.$refs.selectionDialog?.map((item) => {
      if (item.clearValidation) {
        return item.clearValidation();
      }
    });
  }

  @Watch('editRowBaugeldTable') observerEditingRow() {
    this.validateAll();
    this.setValues(this.editRowBaugeldTable);
    this.$emit('modify-edit', true);
  }
  @Watch('copyProduct') observerCopyProduct() {
    this.validateAll();
    this.setValues(this.copyProduct);
  }
}
