
















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations, MutationMethod } from 'vuex';

import StepContent from './StepContent/StepContent.vue';
import Loader from '@/components/fmh_components/common/Loader/Loader.vue';
import Error from '@/components/fmh_components/common/Error/Error.vue';
import Dialog from '@/components/fmh_components/common/Dialog/Dialog.vue';

import stepContent from '@/utils/helpers/wizard/stepContent';
import customStateHandler from '@/utils/helpers/wizard/customStateHandler';
import { BASE_BLACK_COLOR } from '@/utils/constants';

interface ResultData {
  products: Array<object>;
  anbieterAuswahl: Array<string>;
  kreditgeberAuswahl: Array<string>;
  is_temporary: boolean;
  selection_title: string;
  uid: string;
  slug: string;
  error: Boolean;
  newReport: object;
  title: string;
  report_type: number;
  updateExistingReport: object;
  column_filter: Array<string>;
  autoupdate_interval: string;
}
interface DataHeaders {
  text: string;
  value: string;
}

interface DataTable {
  headers: Array<DataHeaders> | [];
  rows: Array<any> | [];
}
interface RequiredPayload {
  uid: String;
  title: String;
  slug: String;
  report_type: Number;
  is_temporary: Boolean;
  config: Object;
}

@Component({
  components: {
    StepContent,
    Loader,
    Error,
    Dialog,
  },
  computed: {
    ...mapGetters({
      loadingPreview: 'reports/loadingPreview',
      result: 'reports/result',
      componentsState: 'reports/componentsState',
      previewReport: 'reports/previewReport',
      loadingReports: 'reports/loading',
      loadingWizard: 'wizard/loading',
      error: 'reports/error',
      wizardError: 'wizard/error',
      editReport: 'reports/editReport',
      newReport: 'reports/newReport',
      report_type: 'reports/report_type',
      is_temporary: 'reports/is_temporary',
      updateExistingReport: 'reports/updateExistingReport',
      dataTable: 'reports/dataTable',
      editRow: 'baugeld/editRow',
      links: 'reports/getLinks',
    }),
  },
  methods: {
    ...mapActions({
      fetchPreviewReport: 'reports/fetchPreviewReport',
      fetchUpdatedReport: 'reports/fetchUpdatedReport',
      sendUpdatedExistingReport: 'reports/sendUpdatedExistingReport',
      updateReport: 'reports/updateReport',
      sendAndUpdateExistingReport: 'reports/sendAndUpdateExistingReport',
    }),
    ...mapMutations({
      resetError: 'reports/resetError',
      setSelectedValue: 'reports/setSelectedValue',
      setClass: 'reports/setClassDisabled',
      clearResult: 'reports/clearResult',
    }),
  },
})
export default class Wizard extends Vue {
  @Prop() wizard!: Array<Object>;
  @Prop() onNext: Function | undefined;
  @Prop() onBack: Function | undefined;
  @Prop() drawerApp!: Boolean;

  [x: string]: any;
  dialog: Boolean = false;
  edit: Boolean = false;
  message: String = '';
  color: String = BASE_BLACK_COLOR;
  currentStep: number = 0;
  values = {};
  is_temporary: any;
  editRow: Array<Object> | any;
  componentsState!: Object;
  result!: ResultData;
  previewReport!: ResultData;
  updateExistingReport!: ResultData;
  error!: ResultData;
  newReport!: ResultData;
  title!: ResultData;
  dataTable!: DataTable;
  editReport!: Boolean;
  loadingReports!: Boolean;
  loadingWizard!: Boolean;
  wizardError!: Boolean;
  report_type!: Number;
  reportTypes = [1, 2];
  selectedGroups = {};
  isFixedActionButtons = false;
  fixedActionButtonsWidth = {
    width: '100%',
  };
  isSetDefaultValues = false;

  fetchPreviewReport!: ({
    uid,
    title,
    report_type,
    is_temporary,
    config,
  }) => Promise<Object>;
  fetchUpdatedReport!: (payload: RequiredPayload) => Promise<Object>;
  sendUpdatedExistingReport!: (payload: RequiredPayload) => Promise<Object>;
  updateReport!: (payload: RequiredPayload) => Promise<Object>;
  sendAndUpdateExistingReport!: (payload: RequiredPayload) => Promise<Object>;
  resetError!: MutationMethod;
  setSelectedValue!: MutationMethod;
  setClass!: MutationMethod;
  clearResult!: MutationMethod;

  get dataActionButton() {
    const steps = this.wizard.map((item: any) => item.step_id);
    let o = {};
    steps.map((stepId) => {
      if (stepId === 'save_step') {
        return (o[stepId] = {
          text: 'Speichern',
          className: 'pull-right',
          isShowIcon: true,
          handler: () => this.submitUpdateReport(),
        });
      }
      return (o[stepId] = {
        text: 'Weiter',
        className: 'pull-right',
        isShowIcon: true,
        handler: () => this.goNext(),
      });
    });
    return o;
  }
  get wizardBodyStyle() {
    if (
      this.loadingReports ||
      this.loadingWizard ||
      this.error ||
      this.wizardError
    ) {
      return {
        justifyContent: 'flex-end',
      };
    }
  }
  get wizardStepStyle() {
    return {
      width: `${100 / this.wizard.length}%`,
    };
  }

  @Watch('wizard') defaultValues(val) {
    if (!!val.length && +this.report_type === 2 && !this.isSetDefaultValues) {
      val.forEach((items: any) => {
        const defaultValues = {};
        const storeValues: any = [];
        items.content.forEach((item) => {
          (item.elements || []).forEach((el) => {
            (el.values || []).forEach((i) => {
              if (i?.default) {
                if (
                  el.id === 'laufzeit' &&
                  !Object.keys(this.values?.['laufzeit'] || {}).length
                ) {
                  Object.assign(defaultValues, { [i.value]: true });
                  storeValues.push(i.value);
                }
              }
            });
            if (el.id === 'laufzeit') {
              this.$set(this.values, el.id, defaultValues);
              this.setSelectedValue({ laufzeit: storeValues });
            }
          });
        });
      });
      // delay component rendering
      setTimeout(() => {
        this.isSetDefaultValues = true;
      }, 500);
    }
    setTimeout(() => {
      this.isSetDefaultValues = true;
    }, 500);
  }
  @Watch('report_type') setValue() {
    this.setDefaultValues();
  }
  @Watch('currentStep') fetchTableData() {
    if (
      (this.currentStep === 1 &&
        this.reportTypes.includes(+this.report_type)) ||
      (this.currentStep === 2 && +this.report_type === 3)
    ) {
      this.sendPreviewReport();
    }
  }

  @Watch('drawerApp')
  @Watch('isFixedActionButtons')
  updateActionButtonSWidth() {
    this.onScreenResize();
    this.setActionButtonsWidth();
  }

  @Watch('loadingPreview')
  async checkIsFixed(val) {
    if (!val) {
      await this.$nextTick();
      const innerHeight = window.innerHeight;
      const headerHeight =
        document.getElementById('appHeader')?.clientHeight || 0;
      const footerHeight =
        document.getElementById('appFooter')?.clientHeight || 0;
      const wizardStepsHeight =
        document.getElementById('wizardSteps')?.clientHeight || 0;
      const wizardTitleContainerHeight =
        document.getElementById('wizardTitleContainer')?.clientHeight || 0;

      // only 1 class ".activeStep" is always active
      const activeStepHeight =
        document.querySelector('.activeStep')?.scrollHeight || 0;

      const total =
        activeStepHeight +
        headerHeight +
        footerHeight +
        wizardStepsHeight +
        wizardTitleContainerHeight;
      this.isFixedActionButtons = total > innerHeight;
    }
  }

  onScreenResize() {
    window.addEventListener('resize', () => {
      this.setActionButtonsWidth();
    });
  }
  setActionButtonsWidth() {
    const activeStepWidth =
      document.querySelector('.v-main__wrap')?.clientWidth;
    if (activeStepWidth) {
      this.fixedActionButtonsWidth = {
        width: `${activeStepWidth}px`,
      };
    }
  }
  isFixed(step) {
    return (
      this.isFixedActionButtons &&
      (step.step_id === 'banken_filters_step' ||
        step.step_id === 'duration_select_step' ||
        step.step_id === 'column_select_step')
    );
  }

  // for 1 step tagesgeld
  groupsSelected(val) {
    this.selectedGroups = val;
  }
  goDashboard() {
    this.clearResult();
    return this.$router.push('/');
  }
  handleChangeDialog(val) {
    this.dialog = val;
  }
  //this refers to the baugeld report type, when the modal is closed in the second step. when reopened, the previously selected fields must be cleared
  resetValues() {
    this.edit = false;
    this.values = {
      ...this.values,
      id: '',
      idx: '',
      product_beleihung: '',
      product_beleihung_manual: '',
      product_darlehen: '',
      product_darlehen_manual: '',
      product_kaufpreis: '',
      product_kaufpreis_manual: '',
      product_tilgung: '',
      product_tilgung_manual: '',
      product_zinsbindung: '',
    };
    this.setClass({
      product_tilgung_manual: false,
    });
  }
  content(step) {
    return stepContent(
      step,
      this.report_type,
      this.edit,
      this.dataTable,
      this.$refs,
      this.values,
      this.editRow,
      this.currentStep,
      this.result.products?.length
    );
  }
  getWizardClasses(stepId, index) {
    return {
      container: ![
        'duration_select_step',
        'column_select_step',
        'banken_filters_step',
      ].includes(stepId),
      activeStep: this.currentStep === index,
      inactiveStep: this.currentStep !== index,
      error: this.error,
      wizard__body_step__no__scroll: stepId === 'preview_step',
    };
  }
  getStepClasses(stepId) {
    return {
      wizard_save_step: stepId === 'save_step',
      wizard__step__content__scroll: stepId === 'preview_step',
    };
  }
  // functions modifyEdit, setValueDropDown, only applies to report type 3 (baugeld)
  modifyEdit(data) {
    this.edit = data;
  }
  setClassDisabled(key) {
    switch (key) {
      case 'product_volltilgung':
        this.values['product_volltilgung']
          ? this.setClass({ product_tilgung_manual: true })
          : this.setClass({ product_tilgung_manual: false });
        break;
      default:
        return this.values;
    }
  }
  setValuesFromTable(data) {
    const [[key]] = Object.entries(data);
    if (key === 'product_volltilgung' && this.values[key]) {
      Object.assign(this.values, { product_tilgung_manual: '' });
    }
    this.setClassDisabled(key);
    if (
      [
        'product_kaufpreis_manual',
        'product_kaufpreis',
        'product_darlehen',
        'product_darlehen_manual',
        'product_beleihung',
        'product_beleihung_manual',
        'product_tilgung',
        'product_tilgung_manual',
        'product_zinsbindung',
        'product_zinsbindung_manual',
      ].includes(key)
    ) {
      const values = customStateHandler(key, this.values);
      this.values = Object.assign({}, this.values, values);
    }
    this.values = Object.assign({}, this.values, data);
  }
  validPayload() {
    const { products, ...rest } = this.result;
    const updatedProducts: object[] = [];
    const currencyKeys = [
      'product_kaufpreis_manual',
      'product_darlehen_manual',
    ];
    const percentKeys = ['product_tilgung_manual', 'product_beleihung_manual'];
    products.forEach((item: any) => {
      const o = { ...item };
      Object.keys(o).forEach((key) => {
        if (currencyKeys.includes(key)) {
          const currencyFormatNumber = o[key]
            .replaceAll('.', '')
            .replaceAll(',', '.');
          Object.assign(o, { [key]: currencyFormatNumber });
        }
        if (percentKeys.includes(key)) {
          const percentFormatNumber = o[key].replace(',', '.');
          Object.assign(o, { [key]: percentFormatNumber });
        }
      });
      updatedProducts.push(o);
    });
    const payload = { ...rest, products: updatedProducts };
    const allBanks = [...this.result.anbieterAuswahl];
    const anbieterAuswahl: string[] = [];
    const kreditgeberAuswahl: string[] = [];
    const keys = ['anbieterAuswahl', 'anbieterTyp'];
    if (+this.report_type === 1) {
      Object.assign(payload, { anbieterTyp: [] });
    }
    if (Object.keys(this.selectedGroups).length) {
      const anbieterTyp: number[] = [];
      const groups: number[] = [];
      const anbieterAuswahl: string[] = [];
      const keys: string[] = [];
      Object.keys(this.selectedGroups).forEach((key) => {
        anbieterTyp.push(this.selectedGroups[key]);
      });
      Object.keys(
        (this.wizard as any)?.[0]?.content?.[0]?.elements[0].alle
      ).forEach((key) => {
        if (
          anbieterTyp.includes(
            (this.wizard as any)?.[0]?.content?.[0]?.elements[0].alle[key]
          )
        ) {
          keys.push(key);
        }
      });

      const ids = (
        (this.wizard as any)?.[0]?.content?.[0]?.elements?.[0]?.values || []
      )
        .filter((i) => keys.includes(i?.category))
        .map((item) => +item?.value);
      groups.push(ids);
      this.result.anbieterAuswahl.forEach((id) => {
        if (id && !groups.flat().includes(+id)) {
          anbieterAuswahl.push(id);
        }
      });
      Object.assign(payload, {
        anbieterAuswahl,
        anbieterTyp,
      });
    }
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        if (
          Array.isArray(payload[key]) &&
          !payload[key].length &&
          !keys.includes(key)
        )
          delete payload[key];
      }
    }
    if (this.report_type === 3) {
      allBanks.map((bankId) => {
        if (/[_]/.test(bankId)) {
          const anbieter = bankId.split('_')[0];
          const vermittler = bankId.split('_')[1];
          kreditgeberAuswahl.push(vermittler);
          anbieterAuswahl.push(anbieter);
        } else {
          anbieterAuswahl.push(bankId);
        }
      });
      const unique = anbieterAuswahl.filter(
        (item, index) => anbieterAuswahl.indexOf(item) === index
      );
      Object.assign(
        payload,
        { allBanks: allBanks },
        { kreditgeberAuswahl: kreditgeberAuswahl },
        { anbieterAuswahl: unique }
      );
    }
    return payload;
  }
  goNext() {
    if (this.error) return;
    if (this.$refs.stepContent?.[this.currentStep]?.$refs?.component?.[0]) {
      const component =
        this.$refs.stepContent?.[this.currentStep]?.$refs?.component?.[0];
      if (!!component.validation?.length) {
        if (component.validate().error) {
          this.dialog = true;
          this.message =
            component.validate().message !== undefined
              ? component.validate().message
              : 'Mindestens eine Checkbox muss ausgewählt werden.';
          return;
        }
      }
    }
    if (typeof this.onNext == 'function') {
      if (!this.onNext(this.currentStep)) {
        //returned false. don't do anything
        return;
      }
    }
    if (this.report_type === 3) {
      if (
        this.currentStep === 1 &&
        (this.result.products.length === 0 || this.result.products.length > 5)
      ) {
        this.dialog = true;
        this.message = 'Es müssen 1-5 Produkte ausgewählt werden.';
        return;
      }
    }
    if (this.currentStep < this.wizard.length - 1) {
      this.currentStep += 1;
    }
  }
  async sendPreviewReport() {
    if (this.error) return;
    const payload = this.validPayload();
    if (this.report_type === 3) {
      if (
        this.currentStep === 1 &&
        (this.result.products.length === 0 || this.result.products.length > 5)
      ) {
        this.dialog = true;
        this.message = 'Es müssen 1-5 Produkte ausgewählt werden.';
        return;
      }
    }
    if (this.$refs.stepContent?.[this.currentStep]?.$refs?.component?.[0]) {
      const component =
        this.$refs.stepContent?.[this.currentStep]?.$refs?.component?.[0];
      if (component?.validation?.length) {
        if (component.validate().error) {
          this.dialog = true;
          this.message =
            component?.validate()?.message !== undefined
              ? component?.validate()?.message
              : 'Mindestens eine Spalte muss ausgewählt werden.';
          return;
        }
      }
    }
    if (this.editReport && Object.values(this.updateExistingReport).length) {
      await this.sendUpdatedExistingReport({
        slug: this.updateExistingReport.slug,
        uid: this.updateExistingReport.uid,
        title: this.updateExistingReport.title,
        report_type: this.updateExistingReport.report_type,
        is_temporary: false,
        config: payload,
      });
    }
    if (this.editReport && Object.values(this.newReport).length) {
      await this.fetchUpdatedReport({
        slug: this.newReport.slug,
        uid: this.newReport.uid,
        title: this.newReport.title,
        report_type: this.newReport.report_type,
        is_temporary: this.is_temporary,
        config: payload,
      });
    }
    if (Object.values(this.previewReport).length) {
      await this.fetchUpdatedReport({
        slug: this.previewReport.slug,
        uid: this.previewReport.uid,
        title: 'test',
        report_type: this.previewReport.report_type,
        is_temporary: this.is_temporary,
        config: payload,
      });
    }
    if (
      !this.editReport &&
      !Object.values(this.previewReport).length &&
      !Object.values(this.newReport).length
    ) {
      await this.fetchPreviewReport({
        uid: 'testuserID',
        title: 'test',
        report_type: this.report_type,
        is_temporary: this.is_temporary,
        config: payload,
      });
      if (this.error) {
        return;
      }
    }
  }
  async submitUpdateReport() {
    const payload = this.validPayload();
    if (this.$refs.stepContent?.[this.currentStep]?.$refs?.component?.[0]) {
      const component =
        this.$refs.stepContent?.[this.currentStep]?.$refs?.component?.[0];
      if (component.validation) {
        if (component.validate().error) {
          this.message =
            component.validate().message !== undefined
              ? component.validate().message
              : 'Mindestens eine Spalte muss ausgewählt werden.';
          this.dialog = true;
          return;
        }
      }
    }
    if (this.editReport && Object.values(this.updateExistingReport).length) {
      await this.sendAndUpdateExistingReport({
        slug: this.updateExistingReport.slug,
        uid: this.updateExistingReport.uid,
        title: this.result.selection_title,
        report_type: this.updateExistingReport.report_type,
        is_temporary: false,
        config: payload,
      });
      return this.$router.push(this.links?.report || '/');
    }
    if (this.editReport && Object.values(this.newReport).length) {
      await this.updateReport({
        slug: this.newReport.slug,
        uid: this.newReport.uid,
        title: this.result.selection_title,
        report_type: this.newReport.report_type,
        is_temporary: false,
        config: payload,
      });
      return this.$router.push(this.links?.report || '/');
    }
    if (
      !this.editReport &&
      Object.values(this.previewReport).length &&
      !Object.values(this.newReport).length &&
      !Object.values(this.updateExistingReport).length
    ) {
      await this.updateReport({
        slug: this.previewReport.slug,
        uid: this.previewReport.uid,
        title: this.result.selection_title,
        report_type: this.previewReport.report_type,
        is_temporary: false,
        config: payload,
      });
      return this.$router.push(this.links?.report || '/');
    }
    if (
      !this.editReport &&
      !Object.values(this.previewReport).length &&
      !Object.values(this.newReport).length
    ) {
      await this.fetchPreviewReport({
        uid: 'testuserID',
        title: this.result.selection_title,
        report_type: this.report_type,
        is_temporary: false,
        config: payload,
      });
      if (this.error) {
        return;
      }
      return this.$router.push(this.links?.report || '/');
    }
  }
  goBack() {
    if (typeof this.onBack == 'function') {
      if (!this.onBack(this.currentStep)) {
        return;
      }
    }
    if (this.error) {
      this.resetError();
    }
    if (this.currentStep > 0) {
      this.currentStep -= 1;
    }
  }
  setDefaultValues() {
    const date = this.$date(new Date()).format('DD.MM.YYYY');
    switch (Number(this.report_type)) {
      case 1:
        this.$set(this.values, 'selection_type', 'selection_autoupdate');
        this.$set(this.values, 'autoupdate_interval', 'daily');
        this.$set(this.values, 'selection_title', `Tagesgeld - ${date}`);
        this.setSelectedValue(this.values);
        break;
      case 2:
        this.$set(this.values, 'banken_einlagensicherung', 5);
        this.$set(this.values, 'selection_type', 'selection_autoupdate');
        this.$set(this.values, 'autoupdate_interval', 'daily');
        this.$set(this.values, 'selection_title', `Festgeld - ${date}`);
        const payload = { ...this.values, laufzeit: ['1'] };
        this.setSelectedValue(payload);
        break;
      case 3:
        this.$set(this.values, 'selection_type', 'selection_autoupdate');
        this.$set(this.values, 'autoupdate_interval', 'daily');
        this.$set(this.values, 'selection_title', `Baugeld - ${date}`);
        this.setSelectedValue(this.values);
        break;
      default:
        return (this.values = {});
    }
  }

  mounted() {
    if (this.report_type !== null && !Object.keys(this.values).length) {
      this.setDefaultValues();
    }
  }
}
