














import { Component, Vue, Prop } from 'vue-property-decorator';

import { BASE_BLACK_COLOR } from '@/utils/constants';

@Component({})
export default class Note extends Vue {
  @Prop() id!: string;
  @Prop() label!: string;
  @Prop() value;

  color: String = BASE_BLACK_COLOR;

  get model() {
    return Object.keys(this.value || {}).length === 0 ? '' : this.value;
  }
  set model(val) {
    this.$emit('input', val);
  }
}
