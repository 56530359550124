import DropDown from '@/components/fmh_components/common/DropDown/DropDown';
import Input from '@/components/fmh_components/common/Input/Input';
import CheckboxGroup from '@/components/fmh_components/common/CheckboxGroup/CheckboxGroup';
import GeneralTable from '@/components/fmh_components/common/GeneralTable/GeneralTable';
import FMHAdvancedTable from '@/components/fmh_components/common/FMHAdvancedTable/FMHAdvancedTable';
import Button from '@/components/fmh_components/common/Buttons/Button';
import SubHeadline from '@/components/fmh_components/common/SubHeadline/SubHeadline';
import CheckBox from '@/components/fmh_components/common/CheckBox/CheckBox';
import RadioButtonGroup from '@/components/fmh_components/common/RadioButtonGroup/RadioButtonGroup';
import SelectionDialog from '@/components/fmh_components/common/SelectionDialog/SelectionDialog';
import BaugeldProductsTable from '@/components/fmh_components/common/BaugeldProductsTable/BaugeldProductsTable';
import TagsGroup from '@/components/fmh_components/common/TagsGroup/TagsGroup';
import Note from '@/components/fmh_components/common/Note/Note';

export const FIELD_COMPONENTS = {
  select: DropDown,
  input: Input,
  multiple_select: CheckboxGroup,
  checkbox: CheckBox,
  table_preview: GeneralTable,
  table_preview_festgeld: GeneralTable,
  table_preview_tagesgeld: GeneralTable,
  table_preview_baugeld: FMHAdvancedTable,
  table: BaugeldProductsTable,
  button: Button,
  text: SubHeadline,
  radio: RadioButtonGroup,
  modal: SelectionDialog,
  tags: TagsGroup,
  textfield: Note,
};
