

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { SELECTED_COLOR } from '@/utils/constants';
import { mapGetters, mapMutations, MutationMethod } from 'vuex';

interface ResultData {
  products: Array<object>;
  anbieterAuswahl: Array<string>;
  kreditgeberAuswahl: Array<string>;
  is_temporary: boolean;
  selection_title: string;
  uid: string;
  slug: string;
  error: Boolean;
  newReport: object;
  title: string;
  report_type: number;
  updateExistingReport: object;
  column_filter: Array<string>;
  autoupdate_interval: string;
}

interface Data {
  label: String;
  id: String;
}
interface DataOptions {
  options: Array<Data>;
}
@Component({
  components: {},
  methods: {
    ...mapMutations({
      setSelectedValue: 'reports/setSelectedValue',
    }),
  },
  computed: {
    ...mapGetters({
      result: 'reports/result',
    }),
  },
})
export default class RadioButtonGroup extends Vue {
  @Prop() options!: DataOptions;
  @Prop() label!: String;
  @Prop() value!: String;

  setSelectedValue!: MutationMethod;
  result!: ResultData;
  color: String = SELECTED_COLOR;
  isDisabled = false;

  get model() {
    if (this.value === 'selection_longterm') {
      this.isDisabled = true;
      this.setSelectedValue({ autoupdate_interval: '' });
    }
    return this.value;
  }
  set model(val) {
    if (val !== 'selection_autoupdate') {
      this.isDisabled = true;
      this.setSelectedValue({ autoupdate_interval: '' });
    } else {
      this.setSelectedValue({ autoupdate_interval: 'daily' });
      this.isDisabled = false;
    }
    this.$emit('input', val);
  }
  get modelOption() {
    return this.result.autoupdate_interval;
  }
  set modelOption(val) {
    this.setSelectedValue({ autoupdate_interval: val });
  }
}
