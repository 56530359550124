var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ container_block: _vm.withGroups }},[_c('div',[_c('Dialog',{attrs:{"message":_vm.message,"isShowModal":_vm.isShowModal},on:{"change":_vm.handleChangeModal}}),(_vm.report_type === 3 && _vm.stepId === 'column_select_step')?_c('span',{staticClass:"headline-column"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.withGroups)?_c('div',[(_vm.stepId === 'banken_step')?_c('v-btn',{staticClass:"mb-5",class:{
          all_groups_selected_btn_active: _vm.isAllGroupsSelectionBtnActive,
        },staticStyle:{"color":"#fff"},attrs:{"color":_vm.selectedColor},on:{"click":_vm.handleAllChecked}},[_c('span',[(!_vm.isAllGroupsSelectionBtnActive)?_c('span',[_vm._v("alle auswählen")]):_vm._e(),(_vm.isAllGroupsSelectionBtnActive)?_c('span',[_vm._v("keine auswählen")]):_vm._e()])]):_vm._e(),_vm._l((Object.keys(_vm.groups)),function(groupName,index){return _c('div',{key:index,staticClass:"panel-header",on:{"click":function($event){return _vm.toggleDialogGroup(groupName)}}},[_c('p',[_vm._v(" "+_vm._s(_vm.generateGroupName(groupName))+" ")]),_c('div',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepId !== 'column_select_step'),expression:"stepId !== 'column_select_step'"}],staticClass:"not-active",class:{ active: _vm.counterOffers[groupName].counterOffers }},[_vm._v(" "+_vm._s(_vm.counterOffers[groupName].counterOffers)+" von "+_vm._s(_vm.groups[groupName].length)+" Instituten "),(groupName === 'Vermittler')?_c('span',[_vm._v(" mit "+_vm._s(_vm.counterOffers[groupName].financer)+" möglichen Angeboten")]):_vm._e()]),_c('v-dialog',{attrs:{"content-class":"dialog-group"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":_vm.selectedColor,"data-cy":"chooseBanks"}},'v-btn',attrs,false),on),[_vm._v("auswählen")])]}}],null,true),model:{value:(_vm.dialogGroup[groupName]),callback:function ($$v) {_vm.$set(_vm.dialogGroup, groupName, $$v)},expression:"dialogGroup[groupName]"}},[_c('v-card',{ref:"dialog",refInFor:true},[_c('v-card-title',{staticClass:"group-dialog-title"},[_vm._v(" "+_vm._s(groupName)+" ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text_card"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepId !== 'column_select_step'),expression:"stepId !== 'column_select_step'"}],staticClass:"not-active",class:{
                    'active-group-offers':
                      _vm.counterOffers[groupName].counterOffers,
                  }},[_vm._v(" "+_vm._s(_vm.counterOffers[groupName].counterOffers)+" von "+_vm._s(_vm.groups[groupName].length)+" Instituten gewählt ")]),_c('CheckBox',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCheckAll),expression:"isShowCheckAll"}],attrs:{"label":"Alle/keine auswählen","value":_vm.groupSelected[groupName],"id":Math.random(),"is-show-minus":groupName === 'Vermittler'
                      ? _vm.groupIndeterminate['all']
                      : false,"stepId":_vm.stepId},on:{"input":function () {
                              var args = [], len = arguments.length;
                              while ( len-- ) args[ len ] = arguments[ len ];

                              return groupName === 'Vermittler'
                        ? _vm.toggleGroupVermittler.apply(void 0, args.concat( [groupName] ))
                        : _vm.toggleGroup.apply(void 0, args.concat( [groupName] ));
        }}}),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepId !== 'column_select_step'),expression:"stepId !== 'column_select_step'"}]}),_c('div',{staticClass:"card-content"},_vm._l((_vm.chunks[groupName]),function(items,index){return _c('div',{key:index},_vm._l((items),function(item,index){return _c('div',{key:index},[(groupName === 'Vermittler')?_c('div',{staticClass:"vermittlerContent"},[_c('CheckBox',{attrs:{"label":item.name,"value":_vm.groupValues[item.value],"id":item.value,"is-show-minus":_vm.groupIndeterminate[item.value],"stepId":_vm.stepId},on:{"input":function($event){return _vm.onValuesChangeVermittler($event, item)}}}),_c('v-dialog',{attrs:{"content-class":"dialog-group","width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"addSubBankButton",attrs:{"disabled":_vm.isDisabledBtn(item),"color":_vm.color}},'v-btn',attrs,false),on),[_vm._v("+")])]}}],null,true),model:{value:(item.dialog),callback:function ($$v) {_vm.$set(item, "dialog", $$v)},expression:"item.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" "+_vm._s(item.name)+" - Unterbank hinzufügen ")]),_c('div',{staticClass:"subBanksBlock"},[_c('CheckBox',{attrs:{"label":"Alle/keine auswählen","type":"checkbox","value":_vm.groupValues[item.value],"id":Math.random(),"is-show-minus":_vm.groupIndeterminate[item.value],"stepId":_vm.stepId},on:{"input":function($event){return _vm.toggleGroupSub($event, item)}}}),_c('div',{staticClass:"card-content"},_vm._l((_vm.chunksValues(
                                    item.financer,
                                    16
                                  )),function(chunks,index){return _c('div',{key:index},_vm._l((chunks),function(i,index){return _c('div',{key:index},[_c('CheckBox',{attrs:{"label":i.name,"value":_vm.values[i.value],"id":i.value,"stepId":_vm.stepId},on:{"input":function($event){return _vm.onValuesChange($event, i.value)}}})],1)}),0)}),0)],1),_c('v-divider'),_c('v-card-actions',{attrs:{"data-cy":"vermittlerDialogActions"}},[_c('v-spacer'),_c('v-btn',{staticClass:"vermittler-group-btn",attrs:{"color":_vm.color,"data-cy":"vermittlerDialogActionButton"},on:{"click":function($event){return _vm.handleChangeDialog(item)}}},[_vm._v(" Übernehmen ")])],1)],1)],1)],1):_c('CheckBox',{attrs:{"disabled":_vm.isDisabled(item),"label":item.name,"value":_vm.values[item.value],"id":item.value,"stepId":_vm.stepId},on:{"input":function($event){return _vm.onValuesChange($event, item.value, groupName)}}})],1)}),0)}),0)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"action-group"},[_c('v-btn',{attrs:{"text":"","color":_vm.color},on:{"click":function($event){return _vm.handleChangeDialogGroup(groupName)}}},[_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v("mdi-close")]),_vm._v("Abbrechen ")],1),_c('v-btn',{attrs:{"color":_vm.color,"data-cy":"apply"},on:{"click":function($event){return _vm.handleChangeDialogGroup(groupName)}}},[_vm._v(" Übernehmen ")])],1)],1)],1)],1)])})],2):_c('div',[_c('div',{staticClass:"checkbox-block"},[_c('CheckBox',{directives:[{name:"show",rawName:"v-show",value:(_vm.report_type !== 3),expression:"report_type !== 3"}],attrs:{"label":"Alle/keine auswählen","type":"checkbox","value":_vm.allSelected,"id":Math.random(),"stepId":_vm.stepId},on:{"input":_vm.toggleAll}}),_c('div',{staticClass:"checkbox-container"},_vm._l((_vm.chunksValues(
              _vm.options,
              +this.report_type === 1 ? 3 : _vm.options.length === 4 ? 2 : 4
            )),function(items,index){return _c('div',{key:index,staticClass:"chunks"},_vm._l((items),function(item,index){return _c('div',{key:index,style:(_vm.checkboxGroupStyle)},[_c('CheckBox',{attrs:{"disabled":_vm.isDisabled(item),"label":item.name,"value":_vm.values[item.value],"id":item.value,"stepId":_vm.stepId},on:{"input":function($event){return _vm.onValuesChange($event, item.value)}}})],1)}),0)}),0)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }