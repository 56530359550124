













































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import { validate, ValidationData } from '@/utils/helpers/validation';

Component.registerHooks(['validations']);

@Component({
  computed: {
    ...mapGetters({
      filters: 'reports/result',
      isDisabled: 'reports/getDisabledModalFields',
    }),
  },
})
export default class InputBox extends Vue {
  @Prop({ default: false }) disabled?: boolean;
  @Prop() value!: string | number;
  @Prop() id?: string;
  @Prop() label?: string;
  @Prop({ default: '' }) placeholder?: string;
  @Prop() type!: string;
  @Prop() stepId!: string;
  @Prop() validation!: ValidationData[];
  @Prop() title!: string;

  [x: string]: any;
  error: Boolean = false;
  message: String = '';
  status: String = '';

  symbols = {
    product_kaufpreis_manual: '€',
    product_darlehen_manual: '€',
    product_beleihung_manual: '%',
    product_tilgung_manual: '%',
  };

  handleChangeKeyDown(e, type) {
    if (
      e.key !== 'Tab' &&
      e.key !== ',' &&
      e.key !== '.' &&
      e.key !== 'Backspace' &&
      type === 'number' &&
      !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)
    ) {
      e.preventDefault();
    }
  }

  handleChangeFocus() {
    if (this.error) {
      return (this.error = false);
    }
  }

  validate() {
    if (this.validation.length) {
      this.$v.value.$touch();
      this.error = this.$v.$error;
      this.message = this.validation[0].message;
      return { error: this.$v.$error, message: this.validation[0].message };
    }
  }

  clearValidation() {
    this.error = false;
  }
  validations() {
    return {
      value: {
        required,
        isVal: (value) => {
          // there must be no commas in the string for validation to work correctly
          const newValue = String(value).includes(',')
            ? Number(value.replace(',', '.'))
            : value;
          const validations = this.validation.map((validation: any) => {
            return validate(validation, newValue);
          });
          return (
            validations.filter((item) => item).length === validations.length
          );
        },
      },
    };
  }

  @Emit('input') handleChange(e: InputEvent) {
    this.$v.value.$touch();
    const value = (e.target as HTMLInputElement).value;
    if (this.id?.match('manual')) {
      // the following code refers to the add product form for the baugeld report type
      if (!value) return '';
      if (this.symbols[this.id] === '%') {
        if (value.includes('.')) {
          return value.replace('.', ',');
        }
        return value;
      } else {
        const num = value.replaceAll('.', '').replaceAll(',', '.');
        const formatNums = new Intl.NumberFormat('de-DE');
        const last = value.length - 1;
        const preLast = value.length - 2;
        const symbols = [',', '.'];
        if (
          isNaN(+num) ||
          (symbols.includes(value[last]) && symbols.includes(value[preLast]))
        ) {
          return '';
        }
        return formatNums.format(+num);
      }
    } else {
      return value;
    }
  }
}
