/* eslint-disable */
import { FIELD_COMPONENTS } from '@/utils/helpers/wizard/constants';
import { modifyRowsForBaugeldTable } from '@/utils/helpers/wizard/controllers/modifyRowBaugeldTable';


function contentModal(options, data, reportType, numberOfProducts, edit, refs, values, editRow, currentStep) {
  return options.map(item => {
    let o = {};
    switch (item.type) {
      case 'text':
        o = {
          component: FIELD_COMPONENTS[item.type],
          props: {
            label: item.label,
            type: 'text',
          },
        };
        break;
      case 'select':
          o = {
            model: item.id,
            component: FIELD_COMPONENTS[item.type],
            label: item.label,
            props: {
              id: item.id,
              options: item.values,
              placeholder: 'Bitte wählen...',
              disabled: false,
              label: item.label,
              validation: item.validation || [],
              stepId: data.step_id,
            },
          }
        break;
      case 'button':
        o = {
          component: FIELD_COMPONENTS[item.type],
          model: null,
          props: {
            id: item.id,
            type: 'cta',
            disabled: (reportType === 3 && numberOfProducts >= 5 && !edit),
            stepId: data.step_id,
            label: item.label,
            args: {
              refs, edit, values, editRow, currentStep
            },
            method: reportType === 3 && modifyRowsForBaugeldTable,
            text: reportType === 3 && !edit ? item.label : 'Speichern',
            addStyle: reportType === 3 ? 'margin: 20px 0 20px 0': null,
          },
        };
        break;
      case 'checkbox':
        o = {
          model: item.id,
          component: FIELD_COMPONENTS[item.type],
          props: {
            id: item.id,
            title: item.title,
            label: item.label,
            stepId: data.step_id,
          },
        };
        break;
      case 'input':
        o = {
          model: item.id,
          component: FIELD_COMPONENTS[item.type],
          props: {
            stepId: data.step_id,
            type: item.typeValue,
            placeholder: item.placeholder,
            id: item.id,
            validation: item?.validation.length ? item.validation : [],
            title: item.title || item.label
          }
        }
        break;
      default:
        return {
          props: {
            stepId: null,
            type: null,
          },
        };
    }
    if (item.options) {
      o.options = item.options.map((option) => {
        return {
          model: option.id,
          component: FIELD_COMPONENTS[option.type],
          props: {
            stepId: data.step_id,
            type: option.typeValue,
            placeholder: option.placeholder,
            id: option.id,
            validation: option?.validation.length ? option.validation : [],
            title: option.title
          },
        }
      });
    }
    return o;
  })

}
function stepContent(
  data,
  reportType,
  edit,
  dataTable,
  refs,
  values,
  editRow,
  currentStep,
  numberOfProducts
) {

  const [...content] = data.content;
  return content?.map((element) => {
    return element?.elements?.length
      ? element?.elements.map((item) => {
      let o = {};
      switch (item.type) {
        case 'textfield':
          o = {
            model: item.id,
            component: FIELD_COMPONENTS[item.type],
            props: {
              id: item.id,
              label: item.label,
            },
          };
          break;
        case 'tags':
          o = {
            model: item.id,
            component: FIELD_COMPONENTS[item.type],
            props: {
              id: item.id,
              label: item.label,
              tags: item.predefined,
            },
          };
          break;
        case 'radio':
          o = {
            model: item.id,
            component: FIELD_COMPONENTS[item.type],
            label: item.label,
            props: {
              id: item.id,
              label: item.label,
              options: item.values,
            },
          };
          break;
        case 'text':
          o = {
            component: FIELD_COMPONENTS[item.type],
            props: {
              label: item.label,
              type: 'text',
              stepId: data.step_id,
            },
          };
          break;
        case 'multiple_select':
          o = {
            model: item.id,
            component: FIELD_COMPONENTS[item.type],
            label: item.label,
            props: {
              id: item.id,
              options: item.values,
              label: item.label,
              validation: item.validation,
              stepId: data.step_id,
              meta: item.meta,
              alle: item.alle || {}
            },
          };
          break;
        case 'checkbox':
          o = {
            model: item.id,
            component: FIELD_COMPONENTS[item.type],
            label: item.label,
            props: {
              id: item.id,
              label: item.label,
              stepId: data.step_id,
            },
          };
        break;
        case 'modal':
          o = {
            model: null,
            component: FIELD_COMPONENTS[item.type],
            props: {
              options: contentModal(item.options, data, reportType, numberOfProducts, edit, refs, values, editRow, currentStep),
              stepId: data.step_id,
            },
          };
          break;
        case 'select':
          o = {
            model: item.id,
            component: FIELD_COMPONENTS[item.type],
            label: item.label,
            props: {
              id: item.id,
              options: item.values,
              placeholder: 'Bitte wählen...',
              disabled: false,
              label: item.label,
              validation: item.validation || [],
              stepId: data.step_id,
            },
          };
          break;
        case 'table':
          o = {
            component: FIELD_COMPONENTS[item.type],
            model: null,
            props: {
              id: 'baugeldTable',
              baugeldTableHeaders: item.headers,
              stepId: data.step_id,
              type: 'table',
            },
          };
          break;
        case 'table_preview_baugeld':
          o = {
            model: null,
            component: FIELD_COMPONENTS[item.type],
            props: {
              id: 'tablePreview',
              headers: dataTable.headers,
              rows: dataTable.rows,
              allBanks: dataTable.allBanks,
              stepId: data.step_id,
              type: 'table',
            },
          };
          break;
        case 'table_preview_tagesgeld':
          o = {
            model: null,
            component: FIELD_COMPONENTS[item.type],
            props: {
              id: 'tablePreview',
              headers: dataTable.headers,
              rows: dataTable.rows,
              report_type: reportType,
              stepId: data.step_id,
              type: 'table',
            },
          };
          break;
        case 'table_preview_festgeld':
          o = {
            model: null,
            component: FIELD_COMPONENTS[item.type],
            props: {
              id: 'tablePreview',
              headers: dataTable.headers,
              rows: dataTable.rows,
              report_type: reportType,
              stepId: data.step_id,
              type: 'table',
            },
          };
          break;
        case 'input':
          o = {
            model: item.id,
            component: FIELD_COMPONENTS[item.type],
            props: {
              id: item.id,
              disabled: false,
              label: item.label,
              placeholder: item.placeholder,
              validation: item.validation,
              stepId: data.step_id,
              type: 'text'
            },
          };
          break;
        default:
          return {
            props: {
              stepId: null,
              type: null,
            },
          };
      }
      return o;
    })
      : []
  });
}
export default stepContent;
