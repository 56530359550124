













import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

interface LinksData {
  report: String;
  dashboard: String;
}
@Component({
  components: {},
  computed: {
    ...mapGetters({
      links: 'reports/getLinks',
    }),
  },
})
export default class SubHeadline extends Vue {
  @Prop() label;

  links!: LinksData;

  get validate() {
    return !!Object.keys(this.links).length;
  }
  get text() {
    let start = this.label.replace(
      'Auswertung [report_link]',
      `<a data-cy="goSingleReport" href="${this.links.report}" class="tab-item">Auswertung</a>`
    );
    return start.replace(
      'Dashboard [dashboard_link]',
      `<a href="${this.links.dashboard}" class="tab-item">Dashboard</a>`
    );
  }
}
