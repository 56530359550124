

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations, MutationMethod } from 'vuex';

import Wizard from '@/components/Wizard/Wizard.vue';

interface ReportTypesResponseData {
  data: Object;
}

@Component({
  components: { Wizard },
  computed: {
    ...mapGetters({
      wizard: 'wizard/sections',
      report_type: 'reports/report_type',
      loading: 'wizard/loading',
      products: 'baugeld/rowsForBaugeldTables',
      maxColumns: 'wizard/maxColumns',
      isAuthorized: 'auth/isAuthorized',
      abortControllers: 'reports/getAbortControllers',
    }),
  },
  methods: {
    ...mapActions({
      fetchWizardConfigFestgeld: 'wizard/fetchWizardConfigFestgeld',
      fetchWizardConfigTagesgeld: 'wizard/fetchWizardConfigTagesgeld',
      fetchWizardConfigBaugeld: 'wizard/fetchWizardConfigBaugeld',
      fetchReportTypes: 'reports/fetchReportTypes',
      fetchReportTypesSuccess: 'reports/fetchReportTypesSuccess',
      editExistingReport: 'reports/editReport',
    }),
    ...mapMutations({
      setEditReport: 'reports/setEditReport',
      setRowsBaugeldTable: 'baugeld/setRowsBaugeldTable',
      clearResult: 'reports/clearResult',
      clearSection: 'wizard/clearSection',
      setMaxColumn: 'wizard/setMaxColumn',
      setReportType: 'reports/setReportType',
      setErrorReportType: 'reports/error',
      setErrorWizardConfig: 'wizard/error',
      setLoading: 'wizard/loading',
      fetchWizardConfigBaugeldSuccess: 'wizard/fetchWizardConfigBaugeldSuccess',
      clearControllers: 'reports/clearControllers',
      clearReports: 'reports/clearReports',
    }),
  },
})
export default class WizardContainer extends Vue {
  @Prop() drawerApp!: Boolean;
  report_type: number | undefined;
  column = null;
  products!: Array<Object>;
  isAuthorized!: Boolean;
  abortControllers!: { abort: Function }[] | null;

  fetchWizardConfigFestgeld!: () => Promise<Object>;
  fetchWizardConfigTagesgeld!: () => Promise<Object>;
  fetchWizardConfigBaugeld!: () => Promise<Object>;
  fetchReportTypes!: () => Promise<ReportTypesResponseData>;
  editExistingReport!: (id: String) => Promise<Object>;
  setRowsBaugeldTable!: MutationMethod;
  setEditReport!: MutationMethod;
  fetchWizardConfigBaugeldSuccess!: MutationMethod;
  setMaxColumn!: MutationMethod;
  setReportType!: MutationMethod;
  setErrorReportType!: MutationMethod;
  setLoading!: MutationMethod;
  setErrorWizardConfig!: MutationMethod;
  clearResult!: MutationMethod;
  clearSection!: MutationMethod;
  clearControllers!: MutationMethod;
  clearReports!: MutationMethod;

  nextClicked() {
    return true; //return false if you want to prevent moving to next page
  }
  backClicked() {
    return true; //return false if you want to prevent moving to previous page
  }

  async fetchConfig() {
    switch (this.report_type) {
      case 1:
        await this.fetchWizardConfigTagesgeld();
        break;
      case 2:
        await this.fetchWizardConfigFestgeld();
        break;
      case 3:
        await this.fetchWizardConfigBaugeld()
          .then((res: any) => {
            this.fetchWizardConfigBaugeldSuccess(res.data);
          })
          .catch((e) => {
            if (e.response.status !== 401) {
              this.setLoading(false);
              this.setErrorWizardConfig(true);
              console.log(e.response);
            }
          });
        break;
      default:
        return null;
    }
    if (this.$router.currentRoute.params.id) {
      await this.editExistingReport(this.$router.currentRoute.params.id)
        .then((res: any) => {
          if (res.data) {
            this.setEditReport(res.data[0]);
            if (this.report_type === 3) {
              this.setRowsBaugeldTable(res.data[0]);
            }
          }
        })
        .catch((e) => {
          if (e.response.status !== 401) {
            this.setLoading(false);
            this.setErrorWizardConfig(true);
            console.log(e.response);
          }
        });
    }
  }
  async fetchData() {
    this.clearSection();
    this.clearResult();
    this.setLoading(true);
    await this.fetchReportTypes()
      .then((res) => {
        this.setReportType(res.data);
      })
      .catch((e) => {
        if (e.response.status !== 401) {
          this.setLoading(false);
          this.setErrorReportType(true);
          console.log(e.response);
        }
      });
    await this.fetchConfig();
    this.setLoading(false);
  }

  @Watch('isAuthorized') getConfig(val: boolean) {
    if (val) {
      this.fetchData();
    }
  }
  @Watch('products') changeMaxColumn() {
    if (this.products.length !== 0) {
      this.setMaxColumn(this.products.length);
    }
  }

  get defaultStyle() {
    const height = window.innerHeight - 310;
    return {
      'min-height': `${height}px`,
    };
  }

  created() {
    if (this.abortControllers) {
      this.abortControllers.forEach((item) => {
        item.abort();
      });
      this.clearControllers();
      this.clearReports({
        reports: [
          'allReportsFestgeld',
          'allReportsTagesgeld',
          'allReportsBaugeld',
        ],
        loading: [
          'loadingAllReportsFestgeld',
          'loadingAllReportsTagesgeld',
          'loadingAllReportsBaugeld',
        ],
      });
    }
    if (this.isAuthorized) {
      this.fetchData();
    }
  }
}
