var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 baugeld-products-table mb-5",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.modifiedRows,"hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Keine Daten vorhanden")])]},proxy:true},{key:"item.product_darlehen",fn:function(ref){
var item = ref.item;
return [(item.product_darlehen)?_c('span',[_vm._v(_vm._s(((item.product_darlehen.toLocaleString('de-DE')) + " €")))]):_vm._e(),(item.product_darlehen_manual)?_c('span',[_vm._v(_vm._s(((item.product_darlehen_manual.toLocaleString('de-DE')) + " €")))]):_vm._e()]}},{key:"item.product_tilgung",fn:function(ref){
var item = ref.item;
return [(!item.product_volltilgung)?_c('span',[_vm._v(_vm._s(_vm.replaceDot(item.product_tilgung_manual)))]):_c('span',[_vm._v(_vm._s('Volltilgung'))])]}},{key:"item.product_beleihung",fn:function(ref){
var item = ref.item;
return [(item.product_beleihung)?_c('span',[_vm._v(_vm._s(((item.product_beleihung) + "%")))]):_vm._e(),(item.product_beleihung_manual)?_c('span',[_vm._v(_vm._s(((item.product_beleihung_manual) + "%")))]):_vm._e()]}},{key:"item.product_kaufpreis",fn:function(ref){
var item = ref.item;
return [(item.product_kaufpreis)?_c('span',[_vm._v(_vm._s(((item.product_kaufpreis.toLocaleString('de-DE')) + " €")))]):_vm._e(),(item.product_kaufpreis_manual)?_c('span',[_vm._v(_vm._s(((item.product_kaufpreis_manual.toLocaleString('de-DE')) + " €")))]):_vm._e()]}},{key:"item.product_zinsbindung",fn:function(ref){
var item = ref.item;
return [(item.product_zinsbindung)?_c('span',[_vm._v(_vm._s(((item.product_zinsbindung) + " Jahre")))]):_vm._e(),(item.product_zinsbindung_manual)?_c('span',[_vm._v(_vm._s(((item.product_zinsbindung_manual) + " Jahre")))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.actions === '')?_c('div',{staticClass:"actions"},[_c('div',[_c('span',{on:{"click":function($event){return _vm.deleteRow(item)}}},[_vm._v("Löschen")]),_c('span',{class:{ edit: item.icons.length > 1 },on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("Ändern")]),_c('span',{class:{ disabled: _vm.modifiedRows.length >= 5 },on:{"click":function($event){return _vm.copyProduct(item)}}},[_vm._v("Kopieren")])]),_c('div',_vm._l((item.icons),function(icon,index){return _c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.length > 1),expression:"items.length > 1"}],key:index,on:{"click":function($event){return _vm.customSort(item, icon)}}},[_vm._v(_vm._s(icon))])}),1)]):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }