var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{class:_vm.containerClasses,attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"space-between","no-gutters":""}},_vm._l((_vm.content),function(items,index){return _c('v-col',{key:index,staticClass:"mr-1",class:{
            'tagesgeld-zielgruppe-block':
              +_vm.report_type === 1 && _vm.currentStep === 1 && index === 0,
            'flex-column': +_vm.report_type === 3,
            hideTable: +_vm.report_type !== 3 && index === 2,
          },attrs:{"xs":_vm.report_type === 3 && 12,"sm":_vm.report_type === 3 && 12,"md":_vm.report_type === 3 && 12,"lg":_vm.report_type === 3 && 12,"xl":_vm.report_type === 3 && 12}},_vm._l((items),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
              ([1, 2, 3].includes(_vm.report_type) &&
                item.props.id !== 'tablePreview') ||
              (![1, 2, 3].includes(_vm.report_type) && !_vm.loadingPreview)
            ),expression:"\n              ([1, 2, 3].includes(report_type) &&\n                item.props.id !== 'tablePreview') ||\n              (![1, 2, 3].includes(report_type) && !loadingPreview)\n            "}],key:index,ref:"wizardBlock",refInFor:true,staticClass:"wizard__block",class:_vm.getWizardClasses(item)},[_c('FilterCriteria',{attrs:{"item":item}}),_c('keep-alive',[_c(item.component,_vm._b({ref:"component",refInFor:true,tag:"component",attrs:{"config":_vm.config,"values":_vm.values,"selectedGroups":_vm.selectedGroups,"reportType":_vm.report_type},on:{"modify-edit":_vm.modifyEdit,"item":_vm.setValuesFromTable,"input":function () { return _vm.handleChange(item.props.id, item.props.stepId); },"checked":_vm.checked,"groupsSelected":_vm.groupsSelected},model:{value:(_vm.values[item.model]),callback:function ($$v) {_vm.$set(_vm.values, item.model, $$v)},expression:"values[item.model]"}},'component',item.props,false))],1)],1)}),0)}),1)],1),_c('v-col',{staticClass:"mt-2",class:{ 'pl-3': +_vm.report_type !== 3 },attrs:{"cols":"12"}},[(_vm.isShowActualBtn)?_c('v-btn',{staticStyle:{"color":"#fff"},attrs:{"data-cy":"updateData","loading":_vm.loadingPreview,"disabled":_vm.loadingPreview,"color":_vm.color,"small":"","type":"primary"},on:{"click":_vm.updateTable}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-reload ")]),_vm._v(" Aktualisieren ")],1):_vm._e()],1)],1),(
      (+_vm.report_type !== 3 && _vm.currentStep === 1) ||
      (_vm.report_type === 3 && _vm.currentStep === 2)
    )?_c('v-row',{staticStyle:{"min-height":"140px"}},_vm._l((_vm.content),function(items,index){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
        ([1, 2].includes(+_vm.report_type) && index === 2) ||
        (+_vm.report_type === 3 && index === 1)
      ),expression:"\n        ([1, 2].includes(+report_type) && index === 2) ||\n        (+report_type === 3 && index === 1)\n      "}],key:index},_vm._l((items),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.props.id === 'tablePreview'),expression:"item.props.id === 'tablePreview'"}],key:index,style:(_vm.tableWidth)},[_c('keep-alive',[(item.props.id === 'tablePreview' && !_vm.loadingPreview)?_c(item.component,_vm._b({ref:'table',refInFor:true,tag:"component",attrs:{"reportType":_vm.report_type,"config":_vm.config}},'component',item.props,false)):_vm._e()],1)],1)}),0)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }