






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FilterCheckBox extends Vue {
  @Prop() id!: string;
  @Prop({ default: '' }) label: string | undefined;
  @Prop() value!: boolean;
  @Prop({ default: false }) disabled: boolean | undefined;
  @Prop({ default: false }) isShowMinus: boolean | undefined;
  @Prop() stepId!: string;

  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit('input', value);
  }
}
