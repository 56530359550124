var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wizard"},[_c('Dialog',{attrs:{"message":_vm.message,"isShowModal":_vm.dialog,"dataCy":"wizardActionButton"},on:{"change":_vm.handleChangeDialog}}),(
      +this.report_type === 3 &&
      (_vm.loadingPreview || _vm.loadingReports || _vm.loadingWizard)
    )?_c('div',{staticClass:"wizard_loader__box"},[_c('Loader')],1):_vm._e(),_c('ul',{staticClass:"container wizard__steps",attrs:{"id":"wizardSteps"}},_vm._l((_vm.wizard),function(step,index){return _c('li',{key:index,staticClass:"wizard__step",class:{ active: _vm.currentStep >= index },style:(_vm.wizardStepStyle)},[_c('span',{staticClass:"wizard__step__line"}),_c('span',{staticClass:"wizard__step__label"},[_vm._v(_vm._s(_vm.$t(("wizard.progressBar." + (step.name)))))]),_c('span',{staticClass:"wizard__step__indicator"})])}),0),_c('div',{staticClass:"wizard-content-container"},[(_vm.isSetDefaultValues)?_c('div',{staticClass:"wizard__body"},[(_vm.error || _vm.wizardError)?_c('Error'):_vm._e(),_vm._l((_vm.wizard),function(step,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.currentStep === index &&
          !_vm.error &&
          !_vm.loadingReports &&
          !_vm.loadingWizard &&
          !_vm.wizardError
        ),expression:"\n          currentStep === index &&\n          !error &&\n          !loadingReports &&\n          !loadingWizard &&\n          !wizardError\n        "}],key:index,ref:"wizardBody",refInFor:true,staticClass:"wizard__body__step",class:_vm.getWizardClasses(step.step_id, index),style:(_vm.wizardBodyStyle)},[_c('div',{staticClass:"wizard__step__content",class:_vm.getStepClasses(step.step_id)},[_c('StepContent',{ref:"stepContent",refInFor:true,attrs:{"drawerApp":_vm.drawerApp,"selectedGroups":_vm.selectedGroups,"loadingPreview":_vm.loadingPreview,"currentStep":_vm.currentStep,"content":_vm.content(step),"values":_vm.values,"edit":_vm.edit,"fetchTableData":_vm.sendPreviewReport},on:{"groupsSelected":_vm.groupsSelected,"item":_vm.setValuesFromTable,"modify-edit":_vm.modifyEdit,"reset-selected-values":_vm.resetValues}})],1),_c('div',{staticClass:"wizard__body__actions",class:{
            wizard__body__actions_fixed: _vm.isFixed(step),
          },style:(_vm.isFixed(step) && _vm.fixedActionButtonsWidth)},[_c('div',[_c('v-btn',{staticClass:"pull-left",attrs:{"text":"","color":_vm.color,"disabled":_vm.loadingPreview},on:{"click":function($event){return _vm.goBack()}}},[_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v("mdi-chevron-left")]),_c('span',[_vm._v("Zurück")])],1),_c('v-btn',{attrs:{"text":"","color":_vm.color},on:{"click":_vm.goDashboard}},[_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v("mdi-close")]),_vm._v("Abbrechen ")],1)],1),_c('v-btn',{class:_vm.dataActionButton[step.step_id].className,attrs:{"color":_vm.color,"disabled":(_vm.loadingReports && !_vm.error) || _vm.loadingPreview},on:{"click":function($event){_vm.dataActionButton[step.step_id].handler()}}},[_c('span',[_vm._v(_vm._s(_vm.dataActionButton[step.step_id].text))]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataActionButton[step.step_id].isShowIcon),expression:"dataActionButton[step.step_id].isShowIcon"}],attrs:{"color":"#fff"}},[_vm._v("mdi-chevron-right")])],1)],1)])})],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }