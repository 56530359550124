












import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  components: {},
  computed: {
    ...mapGetters({
      maxColumns: 'wizard/maxColumns',
      sumMeta: 'reports/sumMeta',
      report_type: 'reports/report_type',
    }),
  },
})
export default class FilterCriteria extends Vue {
  @Prop() item!: object;
  maxColumns: number | any;
  sumMeta: number | any;

  isMeta(values) {
    if (values !== undefined) {
      return values.find((item) => {
        return item.hasOwnProperty('meta');
      });
    }
    return false;
  }

  get remainingFields() {
    if (this.maxColumns - this.sumMeta <= 0) {
      return 0;
    }
    return this.maxColumns - this.sumMeta;
  }
}
