












































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters, mapMutations, MutationMethod } from 'vuex';

interface HeaderValue {
  text: string;
  value: string;
}

@Component({
  computed: {
    ...mapGetters({
      items: 'baugeld/rowsForBaugeldTables',
    }),
  },
  methods: {
    ...mapMutations({
      editRow: 'baugeld/editRow',
      deleteRowBaugeldTable: 'baugeld/deleteRowBaugeldTable',
      sortProducts: 'reports/sortProducts',
      setCopyProduct: 'baugeld/setCopyProduct',
      modifySelectionDialog: 'reports/modifySelectionDialog',
      deleteProduct: 'reports/deleteProduct',
      setClass: 'reports/setClassDisabled',
    }),
  },
})
export default class BaugeldProductsTable extends Vue {
  @Prop() baugeldTableHeaders!: Array<Object>;
  headers: Array<HeaderValue> = [];
  items: Array<Object> | any;
  deleteRowBaugeldTable!: MutationMethod;
  editRow!: MutationMethod;
  deleteProduct!: MutationMethod;
  sortProducts!: MutationMethod;
  setCopyProduct!: MutationMethod;
  modifySelectionDialog!: MutationMethod;
  setClass!: MutationMethod;

  validHeaders() {
    this.headers = this.baugeldTableHeaders.map((item: any) => ({
      text: item.name,
      value: item.value,
      sortable: false,
    }));
  }
  replaceDot(item) {
    return `${item.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
    })}%`;
  }
  get modifiedRows() {
    return this.items.map((item, index) => {
      const lastIndex =
        index + 1 === this.items.length ? this.items.length - 1 : -1;
      if (index === 0) {
        return {
          ...item,
          idx: index + 1,
          actions: '',
          icons: ['mdi-chevron-down'],
        };
      }
      if (index >= 1 && index !== lastIndex) {
        return {
          ...item,
          idx: index + 1,
          actions: '',
          icons: ['mdi-chevron-down', 'mdi-chevron-up'],
          style: 'margin-left: 24px',
        };
      }
      if (index === lastIndex) {
        return {
          ...item,
          idx: index + 1,
          actions: '',
          icons: ['mdi-chevron-up'],
        };
      }
    });
  }
  deleteRow(row) {
    this.deleteRowBaugeldTable(row.id);
    this.deleteProduct(row.id);
  }
  edit(row) {
    this.modifySelectionDialog(true);
    this.editRow(row);
    if (row.product_volltilgung) {
      this.setClass({ product_tilgung_manual: true });
    }
  }
  copyProduct(row) {
    this.modifySelectionDialog(true);
    this.setCopyProduct(row);
    if (row.product_volltilgung) {
      this.setClass({ product_tilgung_manual: true });
    }
  }
  customSort(row, icon) {
    const index = this.items.findIndex((item) => item.id === row.id);
    if (icon === 'mdi-chevron-down') {
      this.sortProducts({ row, icon });
      this.items.splice(index + 1, 0, this.items.splice(index, 1)[0]);
    } else {
      this.sortProducts({ row, icon });
      this.items.splice(index - 1, 0, this.items.splice(index, 1)[0]);
    }
  }
  mounted() {
    this.validHeaders();
  }
}
