













































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { mapGetters, mapMutations } from 'vuex';

import FilterCriteria from '@/components/fmh_components/common/FilterCriteria/FilterCriteria.vue';
import { BASE_BLACK_COLOR } from '@/utils/constants';
import { groupBy } from '@/utils/helpers';

interface DataElement {
  model: string;
  props: {
    id: string;
    alle?: {};
    options: {
      id: string;
      value: string | number;
      default?: string | number;
    }[];
  };
  component: Vue.Component;
  map(arg: (h: any) => any);
}

@Component({
  components: { FilterCriteria },
  computed: {
    ...mapGetters({
      filters: 'reports/filtersForEditing',
      report_type: 'reports/report_type',
      config: 'reports/result',
      loadingWizard: 'wizard/loading',
    }),
  },
  methods: {
    ...mapMutations({
      setSelectedValue: 'reports/setSelectedValue',
      saveSelectedCheckboxes: 'reports/saveSelectedCheckboxes',
      saveSumMeta: 'reports/saveSumMeta',
    }),
  },
})
export default class StepContent extends Vue {
  @Prop({ default: () => [] }) content!: Array<DataElement[]>;
  @Prop() selectedGroups;
  @Prop() values;
  @Prop() valuesFromTable;
  @Prop() currentStep;
  @Prop() loadingPreview!: Boolean;
  @Prop() drawerApp!: Boolean;
  @Prop() fetchTableData!: Function;

  report_type?: number | string;
  filters: any;
  tableWidth = {
    width: '100%',
  };

  color = BASE_BLACK_COLOR;

  get isShowActualBtn() {
    if (
      this.currentStep === 1 &&
      (Number(this.report_type) === 1 || Number(this.report_type) === 2)
    ) {
      return true;
    }
    if (this.currentStep === 2 && Number(this.report_type) === 3) {
      return true;
    }
  }
  get containerClasses() {
    return {
      'baugeld-selection': this.currentStep === 1 && this.report_type === 3,
      'baugeld-select-columns':
        this.currentStep === 2 && this.report_type === 3,
      'spalten-step-festgeld':
        (this.currentStep === 1 || this.currentStep === 2) &&
        this.report_type === 2,
      'not-first-step': this.currentStep !== 0,
    };
  }

  saveSelectedCheckboxes!: (checkedBanks) => {};
  setSelectedValue!: (payload: { [id: string]: any }) => {};
  saveSumMeta!: (selectedCheckboxesSumMeta) => {};
  modifyEdit(val) {
    this.$emit('modify-edit', val);
  }
  updateTable() {
    this.tableWidth = {
      width: '100%',
    };
    this.fetchTableData();
  }
  getWizardClasses(item) {
    if (item.props) {
      if (
        ['duration_select_step', 'banken_filters_step'].includes(
          item.props.stepId
        )
      ) {
        return {
          wizard__block__preview: 'wizard__block__preview',
        };
      }
      if (item.props.stepId === 'product_step' && item.props.type !== 'table') {
        return {
          wizard__block__products: 'wizard__block__products',
        };
      }
      if (
        item.props.stepId === 'column_select_step' &&
        this.report_type !== 3
      ) {
        return {
          wizard__block__spalten__step: 'wizard__block__spalten__step',
        };
      }
      if (
        item.props.stepId === 'banken_filters_step' &&
        this.report_type !== 3
      ) {
        return {
          wizard__block__drop_down: 'wizard__block__drop_down',
        };
      }
      if (item.props.type === 'table' && item.props.stepId === 'product_step') {
        return {
          tableBox: item.props.type === 'table',
        };
      }
    }
  }

  //this function is called only in modifyRowBaugeldTable
  resetSelectedValues() {
    this.$emit('reset-selected-values');
  }
  setValuesFromTable(data) {
    this.$emit('item', data);
  }
  handleChange(id, stepId) {
    if (stepId !== 'product_step') {
      this.setSelectedValue({
        [id]: this.values[id],
      });
    }
  }
  groupsSelected(val) {
    this.$emit('groupsSelected', val);
  }
  onScreenResize() {
    window.addEventListener('resize', () => {
      this.handleTableWidth();
    });
  }

  handleTableWidth() {
    const generalTableWidth = (this.$refs as any).table?.[0]?.$el?.firstChild
      ?.scrollWidth;
    const mainWidth = document.querySelector('.v-main__wrap')?.clientWidth || 0;
    const wizardContainerWidth =
      document.getElementById('wizardTitleContainer')?.clientWidth || 0;
    const padding = 32;

    // when the drawer is active
    if (mainWidth - wizardContainerWidth === 0) {
      if (generalTableWidth && generalTableWidth > mainWidth - padding) {
        this.tableWidth = {
          width: `${generalTableWidth}px`,
        };
      } else {
        this.tableWidth = {
          width: '100%',
        };
      }
    } else {
      const different = mainWidth - wizardContainerWidth;
      const result = different + padding + generalTableWidth;
      if (generalTableWidth && result > mainWidth) {
        this.tableWidth = {
          width: `${generalTableWidth}px`,
        };
      } else {
        this.tableWidth = {
          width: '100%',
        };
      }
    }
  }
  @Watch('drawerApp')
  updateWidth() {
    this.onScreenResize();
    this.handleTableWidth();
  }

  @Watch('loadingPreview')
  @Watch('loadingWizard')
  async checkAndUpdateTableWidth(val) {
    if (!val) {
      await this.$nextTick();
      this.handleTableWidth();
    }
  }

  @Watch('checked') checked(val) {
    this.saveSelectedCheckboxes(val.checked);
    if (val.stepId === 'column_select_step' && this.report_type === 3) {
      this.saveSumMeta(val.selectedSum);
    }
  }

  @Watch('filters', { immediate: true }) onSetFilters() {
    if (!!Object.keys(this.filters).length) {
      Object.keys(this.values).forEach((key) => {
        if (typeof this.filters[key] === 'object' && key !== 'tags') {
          this.$set(this.values, key, {});
        }
      });
      this.content.forEach((items) => {
        items.forEach((o: DataElement) => {
          if (
            this.filters[o.props.id] &&
            Array.isArray(this.filters[o.props.id]) &&
            o.props.id !== 'tags'
          ) {
            const selectedCategories = {};
            // not all banks in the category are selected
            const result = this.filters[o.props.id].reduce(
              (all, curr) => ({ ...all, [curr]: true }),
              {}
            );
            if (
              (this.filters.anbieterTyp || []).length &&
              Object.keys(items?.[0]?.props?.alle || {}).length
            ) {
              const groupsSelected = {};
              const keys: string[] = [];
              const groups = groupBy(items[0].props.options, 'category');
              Object.keys(groups).forEach((key) => {
                if (keys.includes(key)) {
                  groups[key].forEach((bank) => {
                    Object.assign(selectedCategories, { [bank.value]: true });
                  });
                }
              });
              Object.keys(items[0].props.alle || {}).forEach((key) => {
                if (
                  this.filters.anbieterTyp.includes(items[0].props.alle?.[key])
                ) {
                  keys.push(key);
                  Object.assign(groupsSelected, {
                    [key]: items[0].props.alle?.[key],
                  });
                }
              });
              this.$emit('groupsSelected', groupsSelected);
              items[0].props.options.forEach((bank: any) => {
                if (keys.includes(bank.category)) {
                  Object.assign(selectedCategories, { [bank.value]: true });
                }
              });
            }

            this.$set(
              this.values,
              o.props.id,
              Object.assign(result, selectedCategories)
            );
          }
          if (
            this.filters[o.props.id] &&
            Array.isArray(this.filters[o.props.id]) &&
            o.props.id === 'tags'
          ) {
            this.$set(this.values, o.props.id, this.filters[o.props.id]);
          }
          if (
            !Array.isArray(this.filters[o.props.id]) &&
            typeof o.props.id !== 'undefined'
          ) {
            this.$set(this.values, o.props.id, this.filters[o.props.id]);
          }
          if (this.report_type !== 3) {
            if (typeof this.filters[o.props.id] === 'number') {
              this.$set(
                this.values,
                o.props.id,
                Number([this.filters[o.props.id]])
              );
            }
            if (typeof this.filters[o.props.id] === 'string') {
              this.$set(this.values, o.props.id, this.filters[o.props.id]);
            }
          }
          if (this.report_type === 3 && o.props.id === 'selection_title') {
            this.$set(this.values, o.props.id, this.filters[o.props.id]);
          }
        });
      });
    }
  }
}
