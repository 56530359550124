function customStateHandler(key, data) {
  const values = {};
  const priceFormat = data.product_kaufpreis_manual
    ?.replaceAll('.', '')
    ?.replaceAll(',', '.');
  const price = +priceFormat;
  const darlehenFormat = data.product_darlehen_manual
    ?.replaceAll('.', '')
    ?.replaceAll(',', '.');
  const beleihungFormat = data.product_beleihung_manual
    ?.replaceAll('.', '')
    ?.replaceAll(',', '.');
  const darlehen = +darlehenFormat;
  const beleihung = +beleihungFormat;

  if (key === 'product_kaufpreis_manual' && darlehen && beleihung) {
    values.product_darlehen_manual = '';
    values.product_beleihung_manual = '';
  }

  if (key === 'product_beleihung_manual' && price) {
    const result = (price * beleihung) / 100;
    values.product_darlehen = '';
    values.product_beleihung = '';
    values.product_darlehen_manual = !isNaN(result)
      ? result.toLocaleString('de-DE', {
          currency: 'EUR',
        })
      : '';
  }
  if (key === 'product_darlehen_manual' && price) {
    values.product_darlehen = '';
    values.product_beleihung = '';
    values.product_beleihung_manual =
      price !== 0
        ? String(((darlehenFormat / price) * 100).toFixed(2)).replace('.', ',')
        : '';
  }

  if (key === 'product_zinsbindung') {
    values.product_zinsbindung_manual = '';
  }
  if (key === 'product_tilgung') {
    values.product_tilgung_manual = '';
  }
  if (key === 'product_tilgung_manual') {
    values.product_tilgung = '';
  }
  return values;
}

export default customStateHandler;
