



























import { Component, Vue, Prop } from 'vue-property-decorator';

import { BASE_BLACK_COLOR } from '@/utils/constants';

@Component({})
export default class TagsGroup extends Vue {
  @Prop() tags!: Array<Object>;
  @Prop() label!: String;
  @Prop() id!: String;
  @Prop() value!: String;

  selectedTags = [];
  color: String = BASE_BLACK_COLOR;

  get model() {
    return this.value;
  }
  set model(val) {
    this.$emit('input', val);
  }
}
